import React from 'react';
import styled from 'styled-components';
import {MUD_COLOR, LIGHT_MUD_COLOR, WHITE_COLOR } from '../styles/styles';

const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 800px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const PhotoContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${(props) => (props.backgroundColor)};
`;

const Photo = styled.div`
  width: 80%;
  height: 80%;
  background-color: ${(props) => (props.backgroundColor)};
  top: 10%;
  position: relative;
  display: inline-block;
`;

const PhotoWidget = () => {
  return (
    <SectionContainer>
      <PhotoContainer backgroundColor={WHITE_COLOR}>
        <Photo backgroundColor={MUD_COLOR}/>
      </PhotoContainer>
    </SectionContainer>
  )      
};

export default PhotoWidget;
