/**
 * colors
 */
export const MAIN_COLOR = 'rgb(34, 62, 40);';
export const MUD_COLOR = 'rgb(195, 184, 169);';
export const LIGHT_MUD_COLOR = 'rgb(245, 242, 237);';
export const WHITE_COLOR = 'rgb(255, 255, 255);';
export const SECONDARY_COLOR = '#c0fe8b';
/**
 * font family
 */
export const LOGO_FONT_FAMILY = 'IBM Plex Sans';
export const BAR_MENU_TEXT_FAMILY = 'Golos Text, sans-serif';
