import React from 'react';
import styled from "styled-components";
import PhotoWidget from './PhotoWidget';

const StyledBodyContainer = styled.div`
	height: 100%;
	top: 125px;
	position: relative;
`;

const Homepage = () => {
	return (
    <StyledBodyContainer>
			<PhotoWidget />
			<PhotoWidget />
			<PhotoWidget />
			<PhotoWidget />
			<PhotoWidget />
    </StyledBodyContainer>
	);
}

export default Homepage;
 