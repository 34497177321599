import './App.css';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Outlet } from "react-router-dom";
import Header from './components/header';
import { MAIN_COLOR } from './styles/styles';
import BarMenu from './components/BarMenu';

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
`;

function App() {
  const [isHeaderHidden, setHeaderHidden] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      console.log('.........', isHeaderHidden, window.scrollY > 300)
      if (window.scrollY > 300) {
        console.log('...')
        setHeaderHidden(true);
      } else {
        setHeaderHidden(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <StyledContainer className="App">
      <Header isHeaderHidden={isHeaderHidden} />
      <Outlet />
    </StyledContainer>
  );
}

export default App;
