import React from "react";
import styled from "styled-components";
import { BAR_MENU_TEXT_FAMILY } from '../styles/styles';
import {MUD_COLOR, LIGHT_MUD_COLOR, MAIN_COLOR } from '../styles/styles';

const StyledBarMenu = styled.div`
  @media screen and (max-width: 770px) {
    display: none;
  }
  z-index: 1000;
  background-color: ${MAIN_COLOR};
  ${props => props.isHeaderHidden ? 'visibility: hidden; transition: visibility 0s, opacity 0.005s linear;' : ''}
  width: 99.9%;
  position: absolute;
  border-width: 0.5px;
  border-color: white;
  border-style: solid;
`;

const StyledSmallMenu = styled.span`
  color: white;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  text-align: center;
  width: 25%; 
  font-family: ${BAR_MENU_TEXT_FAMILY};
  background-color: ${MAIN_COLOR};
`;

const SmallMenuContainer = styled.div`
  width: 700px;
  margin: 0 auto;
  text-align: center;
  line-height: 50px;
  font-size: 10px;
  letter-spacing: 2px;
  z-index: 99;
  background-color: ${MAIN_COLOR};
`;

const BarMenu = ({ isHeaderHidden }) => {
  return (
    <StyledBarMenu isHeaderHidden={isHeaderHidden}>
      <SmallMenuContainer>
        <StyledSmallMenu>TARTS</StyledSmallMenu>
        <StyledSmallMenu>STRAWBERRIES</StyledSmallMenu>
        <StyledSmallMenu>INGREDIENTS</StyledSmallMenu>
        <StyledSmallMenu>CONTACT US</StyledSmallMenu>
      </SmallMenuContainer>
    </StyledBarMenu>
  );
}

export default BarMenu;
