import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components'
import { MAIN_COLOR, LOGO_FONT_FAMILY } from '../styles/styles';
import BarMenu from './BarMenu';
import {MUD_COLOR, LIGHT_MUD_COLOR, WHITE_COLOR } from '../styles/styles';
  
const StyledCremeText = styled.div`
padding-top: 35px;
margin: 0 auto;
color: white;
font-family: ${LOGO_FONT_FAMILY};
font-size: 34px;
letter-spacing: 7px;
`;

const StyledSFText = styled.div`
font-family: Georgia, serif;
color: white;
font-size: 9px;
letter-spacing: 7px;
`;

const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  width: 99.9%;
  z-index: 999;
  background-color: ${MAIN_COLOR};
  color: black;
  border-width: 0.5px;
  border-color: white;
  border-style: solid;
`;

const TextContainer = styled.div`
  height: 125px;
`;

function Header({ isHeaderHidden }) {
  return (
    <HeaderContainer>
      <TextContainer>
        <StyledCremeText>
          CREMEBERRY 
        </StyledCremeText>
        <StyledSFText>
          SAN FRANCISCO
        </StyledSFText>  
      </TextContainer>
      <BarMenu isHeaderHidden={isHeaderHidden}/>
    </HeaderContainer>
  );
}

export default Header;
