import React from 'react';
import PhotoWidget from './PhotoWidget';

const Tarts = () => {
	return (
		<>
			<PhotoWidget />
			<div>asdfasdfadfdafa</div>
		</>
	);
}

export default Tarts;
 